import React, { useState, useEffect }  from 'react';
import axios from "axios";
import { useForm, Controller } from "react-hook-form"
import { Select, Input, message } from "antd";
import FormData from 'form-data';
import SecureButton from '../../form/SecureButton';

/**
 * NOTE: This component represents the Fault Report (felrapport)-form
 */

const { TextArea } = Input;

/**
 * @brief Adds the title of all available cities
 * @param {JSON} data All job data 
 * @returns A list of type {value: '..', label: '..'}
 */
function addAvailableTitles(data) {
    const availableTitles = [];
    for (let i = 0; i < data.length; i ++) {
        availableTitles.push({ value: i, label: data[i].name });
    }
    return availableTitles;
}

// Displays error message if form field input is wrong
const errorMessage = (error) => (
    <p className='form__error-message'>{error && ('(' + error.message + ')')} </p>
);

const BETWEEN_SEND_TIME = 2000; // 2 seconds between each try

// A contact form component
const ContactForm = ({ language, text }) => {
    const [customers, setCustomers] = useState([]);
    
    // For accepting the terms
    const [accept, setAccept] = useState(false);
    const [sendTime, setSendTime] = useState(Date.now());
    const onAccept = (value) => { setAccept(value); }

    useEffect(() => {
        const url = process.env.REACT_APP_BACKEND_PHP;
        axios.get(url + 'CUSTOMERS/') 
          .then(response => {
            const processData = response.data;
            setCustomers(processData);
          })
          .catch(error => {
            console.log(error);
          });
      }, []);

    // Used for the form
    const { reset, handleSubmit, register, control, formState: { errors } } = useForm();
    // Used for pop-up message when applying for job
    const [messageApi, contextHolder] = message.useMessage();

    // Get all available cities 
    const availableTitles = addAvailableTitles(customers);

    // Submit button action
    async function onSubmit(data) {
        if (!accept) return;
        if ((Date.now() - sendTime) < BETWEEN_SEND_TIME) return; // Mimimum time to wait between each send to avoid spamming

        const url = process.env.REACT_APP_PHP_FAULTFORM;
        // Find job title
        const customer = data['customerOption'];
        const found = availableTitles.findIndex((e) => e.value === customer);
        // Create a new FormData object to send the data
        const formData = new FormData();
        formData.append('customer', availableTitles[found].label);
        formData.append('area', data['area']);
        formData.append('address', data['address']);
        formData.append('text', data['message']);
        formData.append('name', data['name']);
        formData.append('phone', data['phone']);
        formData.append('email', data['email']);
        formData.append('file_image', data['file_image'][0]);

        // Send the request using the Fetch API
        await fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
        .then(response => {
            if (response.status === 200) submitMessage(data, 'success');
            if (response.status === 412) submitMessage(data, 'no-files');
            if (response.status === 413) submitMessage(data, 'too-big');
            if (response.status === 408) submitMessage(data, 'mail-failed');
            setSendTime(Date.now());
        })
        .catch(error => {
            submitMessage(data, false)
            console.error(error)
            setSendTime(Date.now());
        } );

        reset();
    } 

    const submitMessage = (data, success) => {
        if (success === 'success') messageApi.info(text["form_success1"] + data.name + text["form_success2"]);
        if (success === 'no-files') messageApi.info(`FEL: Du måste bifoga en bild!`);
        if (success === 'too-big') messageApi.info(`FEL: Bilden är för stor!`);
        if (success === 'mail-failed') messageApi.info(`FEL: Problem att nå mailservern!`);
        if (success === 'error') messageApi.info(text["form_failure"]);
    };

    return (
        <div className='work__landing-form'>
            <div className='form__container'>
                <h1>{text['contact-error-report-send']}</h1>
                <br />
                <form onSubmit={(e) => {handleSubmit(onSubmit)(e);}}>
                    <div className='form__container-row'>
                        <div className='form__select'>
                            <label>{text['form_customer']}</label>
                            <Controller
                                className="form__select_object"
                                name="customerOption"
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                <Select 
                                    {...field}
                                    options={availableTitles}
                                    
                                />
                            )}/>   
                            </div>  
                        <div className='form__field'>
                            <label>{text['form_area']}</label>
                            <Controller
                                name="area"
                                control={control}
                                render={({ field }) => (
                                    <Input {...field} placeholder={text['form_placeholder-area']} />
                                )}
                                rules={{
                                    required: text['form_mandatory'],
                                    maxLength: 40,
                                    pattern: {
                                    value: /^[a-zA-ZåÅäÄöÖüÜéÉ'\-\s]*$/,
                                    message: text['form_only_letters']
                                    }
                                }}
                            />
                            {errorMessage(errors.area)}
                        </div>
                        <div className='form__field'>
                            <label>{text['form_address']}</label>
                            <Controller
                                name="address"
                                control={control}
                                render={({ field }) => (
                                    <Input {...field} placeholder={text['form_placeholder-address']} />
                                )}
                                rules={{
                                    required: text['form_mandatory'],
                                    maxLength: 40,
                                    pattern: {
                                    value: /^[0-9a-zA-ZåÅäÄöÖüÜéÈ\-'\s]*$/,
                                    message: text['form_only_letters']
                                    }
                                }}
                            />
                            {errorMessage(errors.address)}
                        </div>
                        <div className='form__field'>
                            <label>{text['form_name']}</label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <Input {...field} placeholder={text['form_placeholder-name']} />
                                )}
                                rules={{
                                    required: text['form_mandatory'],
                                    maxLength: 40,
                                    pattern: {
                                    value: /^[a-zA-ZåÅäÄöÖüÜéÉ'\-\s]*$/,
                                    message: text['form_only_letters']
                                    }
                                }}
                            />
                            {errorMessage(errors.name)}
                        </div>
                        <div className='form__field'>
                            <label>{text['form_phone']}</label>
                            <Controller
                                name="phone"
                                control={control}
                                render={({ field }) => (
                                    <Input {...field} placeholder={text['form_placeholder-phone']} />
                                )}
                                rules={{
                                    required: text['form_mandatory'],
                                    maxLength: 20,
                                    pattern: {
                                    value: /^[0-9\+\-]*$/,
                                    message: text['form_only_digits']
                                    }
                                }}
                            />
                            {errorMessage(errors.phone)}
                        </div>
                    </div>
                    <div className='form__container-row'>
                    <div className='form__field'>
                        <label>{text['form_mail']}</label>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <Input {...field} placeholder={text['form_placeholder-mail']} />
                            )}
                            rules={{
                                required: text['form_mandatory'],
                                maxLength: 50,
                                pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: text['form_only_mail']
                                }
                            }}
                        />
                        {errorMessage(errors.email)}
                    </div>
                    <div className='form__field'>
                        <label>{text['form_text']}</label>
                        <Controller
                            name="message"
                            control={control}
                            render={({ field }) => (
                                <TextArea {...field} placeholder={text['form_placeholder-info']} autoSize={{ minRows: 4.5, maxRows: 4.5 }} />
                            )}
                            rules={{
                                required: text['form_mandatory'],
                                maxLength: 256,
                                pattern: {
                                value: /^[()0-9a-zA-ZåÅäÄöÖüÜéÉ\-',.!?%/\s]*$/,
                                message: text['form_only_letters']
                                }
                            }}
                        />
                        {errorMessage(errors.message)}
                    </div>
                    <div className='form__field-file'>
                        <label>{text['form_image']}</label>
                        <input 
                            type="file" 
                            {...register('file_image')}
                            accept=".jpg, .jpeg, .png" 
                        />
                            <span>(max 2 MB)</span>
                    </div>
                    <button disabled={!accept}  className='button-6'> {text['form_send']} </button>
                    {contextHolder}
                    </div>
                </form>
                <SecureButton onAccept={onAccept} language={language} color={"white"} align={"row"} />
            </div>
        </div>
    );
}

export default ContactForm;